export enum EStatusCode {
    NOT_FOUND = 404,
    UNAUTHORIZED = 403,
    MAINTENANCE = 490,
    IP_FORBIDDEN = 491,
    SYSTEM_WARNING = 499,
    SYSTEM_WARNING_PARAMETER = 498,
    BAD_REQUEST = 500,
    LABORATORY_INACTIVE = 399,
}

export enum EStatusCodeText {
    NOT_FOUND = 'not-found',
    UNAUTHORIZED = 'unauthorized',
    MAINTENANCE = 'maintenance',
    BAD_REQUEST = 'bad-request',
    IP_FORBIDDEN = 'ip-forbidden',
    LABORATORY_INACTIVE = 'inactive',
}
